.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.center.night {
  background-color: #111;
  color: #fff;
}

.time-passed {
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  font-size: 200%;
}

.time-passed>p {
  margin: 0 40px;
}

.time-passed>p>p {
  font-weight: 100;
}

.letter {
  max-width: 500px;
}

.stars {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.star {
  position: absolute;
  background-color: #fff;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  animation: twinkle 1s infinite;
}

@keyframes twinkle {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.5); opacity: 0.5; }
  100% { transform: scale(1); opacity: 1; }
}
@media (max-width: 720px) {
  .time-passed {
    flex-wrap: wrap;
    font-size: 30px;
  }

  .time-passed>p {
    margin: 0;
    width: 50%;
    text-align: center;
  }
}